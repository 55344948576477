import { render, staticRenderFns } from "./SyntheticMediaHistorical.vue?vue&type=template&id=b475009a&scoped=true&"
import script from "./SyntheticMediaHistorical.vue?vue&type=script&lang=js&"
export * from "./SyntheticMediaHistorical.vue?vue&type=script&lang=js&"
import style0 from "./SyntheticMediaHistorical.vue?vue&type=style&index=0&id=b475009a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b475009a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardActions,VCardText,VCol,VDialog,VIcon,VImg,VList,VListItem,VMenu,VRadio,VRadioGroup,VRow,VSheet,VSpacer})
